
var adBlockEnabled = false;
var testAd = document.createElement('div');
testAd.innerHTML = '&nbsp;';
testAd.className = 'adsbox';
document.body.appendChild(testAd);
var global_addblock = false;

var sendAdblockValue= function(type){
    console.log('sendAdblockValue ', sendAdblockValue);
    $.ajax({
        type: 'GET',
        url: '/adblock/' + type + '/not_show'
    });
}
window.setTimeout(function() {
    console.log('app_session_adblock_var_app_session_adblock_var start', app_session_adblock_var)
    if(typeof app_session_adblock_var !== 'undefined' && app_session_adblock_var == "show") {
        if (testAd.offsetHeight === 0) {
            adBlockEnabled = true;
        }
        testAd.remove();
        //console.log('AdBlock Enabled? ', adBlockEnabled);
        if (adBlockEnabled && !global_addblock) {
            swal({
                    title: "Warning",
                    text: "Galeforcemedia might not work as expected when an ad blocker is enabled in a web browser. Turn off the ad blocker or add this web page's URL as an exception so you can create ads without any problems. After you turn off the ad blocker, you'll need to refresh your screen.",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonClass: "btn-danger",
                    confirmButtonText: "Don't show me this message again",
                    closeOnConfirm: true,
                    closeOnCancel: true,
                    cancelButtonText: "Close",
                },
                function (isConfirm) {
                    if(isConfirm){
                        global_addblock = true;
                        app_session_adblock_var = "not_show";
                        sendAdblockValue("app_session_adblock_var");
                    }
                    //callDonotTrack()
                });
            console.log("addblock");
        } else {
            console.log('app_session_adblock_var_app_session_adblock_var end', app_session_adblock_var)
            console.log(adBlockEnabled);
            console.log(global_addblock);
            //callDonotTrack()
        }
    }
    else{
        //callDonotTrack()
    }
}, 1000);

/*var donottrack = false;
var callDonotTrack = function() {
    window.setTimeout(function () {
        //console.log('AdBlock Enabled? ', adBlockEnabled);
        if (app_session_donottrack_var == "show") {
            if (navigator.doNotTrack == 1 && !donottrack) {
            swal({
                    title: "Warning",
                    text: "MediaForce might not work as expected when your browser's \"Do Not Track\" feature is enabled. Please disable this feature and refresh your screen.",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonClass: "btn-danger",
                    confirmButtonText: "Don't show me this message again",
                    closeOnConfirm: true,
                    closeOnCancel: true,
                    cancelButtonText: "Close",
                },
                function (isConfirm) {
                    if(isConfirm) {
                        donottrack = true;
                        app_session_donottrack_var = "not_show";
                        sendAdblockValue("app_session_donottrack_var")
                    }
                });
                console.log("donottrack");
            } else {
                console.log(navigator.doNotTrack);
                console.log(donottrack);
            }
        }
    }, 1000);
}*/


/**
 * Main application jQuery logic.
 */

/**
 * If an error is encountered via ajax, return the response in an modal for debug.
 *
 * @param responseText
 */
function debug(responseText) {
    var modal = $('#js-debug-modal');
    if((typeof responseText === 'undefined' || responseText.indexOf("Unauthenticated.") === -1) && !login_redirected_app) {
        if (modal.length) {
            if (app_env_value == "production") {
                modal.find('.modal-title').html("Something went wrong!");
                modal.find('.modal-body').html('The application has encountered an unknown error. Please try again. <br /><br />If the error persists, please contact the support team: <a href="javascript:void(0);" data-toggle="modal" data-target="#globalHelpModal" title="Help &amp; Support"><i class="fa fa-life-ring fa-fw"></i>Help &amp; Support</a>');
                modal.modal('show');
            } else {
                modal.find('.modal-body').html(responseText);
                modal.modal('show');
            }
        }
    }else{
        window.location.reload();
        login_redirected_app = true;
    }
    console.log(app_env_value);
    console.log('responseText: ', responseText);
}

/**
 * Show main container loader.
 */
function show_loader(event = null) {
    console.log(event)
    if((event !== null && event.ctrlKey) || (typeof wsGoingOut !== "undefined" && !wsGoingOut)){}
    else
        $('.mainloader').fadeIn();
}

/**
 * Hide main container loader.
 */
function hide_loader() {
    $('.mainloader').fadeOut();
}

function show_overlay_loader(container) {
    $(container).prepend('<div class="mainloader" style="margin-top: 0;"><div class="cssload-speeding-wheel"></div></div>');
}

function show_positioned_overlay_loader(container) {
    $(container).prepend('<div class="customloader" style="margin-top: 0;position: absolute;"><div class="cssload-speeding-wheel"></div></div>');
}

function hide_overlay_loader(container) {
    $(container).find('.mainloader').remove();
}

function hide_positioned_overlay_loader(container) {
    $(container).find('.customloader').remove();
}

/**
 * Init switchery
 */
var init_switchery = function () {
    $('.js-switch').each(function () {
        if (!$(this).hasClass('switch-init')) {
            new Switchery($(this)[0], $(this).data());
            $(this).addClass('switch-init');
        }
    });
};

$(document).ready(function () {
    init_switchery();
    $(".select2-selection.select2-selection--multiple:not(:has(i.fa.fa-search.search-icon-input))").prepend("<i class='fa fa-search search-icon-input'></i>");
    $('.open-market-li a').on('click', function(e){
        show_loader(e);
    })
});

$(document).on('click', '.mm-fullsubopen', function(event) {
    console.log("INIT");
    var href = $(event.target).attr('href');
    if(href.indexOf('adv-content') !== -1) {
        var arr = href.split("-");
        var advertiser_id = arr[arr.length - 1];
        $("#adv-content-" + advertiser_id).html('<div style="margin-top: 50px" class="text-center"><div class="cssload-speeding-wheel"></div><br><h4>Loading Advertiser ...</h4></div>');
        $.ajax({
            type: "GET",
            url: '/advertiser/'+ advertiser_id +'/loading/menu',
            dataType: 'json',
            success: function (data) {
                $("#adv-content-" + advertiser_id).html(data['data']);
                for(var ind in data['panels']){
                    if($("#camp-content-" + ind).length === 0){
                        $(".mm-panels").append(data['panels'][ind]);
                        console.log("new: " + ind);
                    }else{
                        console.log($("#camp-content-" + ind).length);
                    }
                }
                console.log("DONE");
            },
            error: function (xhr) {
                console.log("ERROR:");
                ajax_error();
            }
        });
    }else if(href.indexOf('camp-content') !== -1){
        console.log("CAMP");
        var arr = href.split("-");
        var camp_id = arr[arr.length - 1];
        arr = $(event.target).attr("data-adv").split("-");
        var adv_id = arr[arr.length - 1];
        var locked = $(event.target).attr("data-locked");
        $(".campaign_" + camp_id).html('<div style="margin-top: 50px" class="text-center"><div class="cssload-speeding-wheel"></div><br><h4>Loading Campaign ...</h4></div>');
        $("#camp-content-" + camp_id).removeClass("mm-hidden");
        $.ajax({
            type: "GET",
            url: '/advertiser/'+ adv_id +'/campaign/'+ camp_id +'/loading/menu/' + locked,
            dataType: 'json',
            success: function (data) {
                $(".campaign_" + camp_id).html(data['data']);
                //$("#camp-content-" + camp_id).addClass("mm-iconpanel-" + camp_id);
                console.log("DONE CAMP");
            },
            error: function (xhr) {
                debug(xhr.responseText);
                ajax_error();
            }
        });
    }
});
$(document).on('keyup', '.menu_custom_search',function () {
    console.log($(".menu_custom_search").val())
    console.log($(this).parent().parent(),$(this).parent().parent().children("ul.mm-listview").children("li"))
    $(this).parent().parent().children("ul.mm-listview").children("li").each(function (item, value) {
        console.log(item,value)
        if(typeof $(value).attr("title") !== "undefined"){
            if($(value).attr("title").toLowerCase().includes($(".menu_custom_search").val().toLowerCase()))
                $(value).css("display","block")
            else
                $(value).css("display","none")
        }
    })
})
$(document).on('click', '.open-market-li-blocked',function () {
    var text = $(this).attr('title');
    swal({
            title: "Warning!",
            text: text,
            type: "warning",
            showCancelButton: false,
            confirmButtonClass: "btn-danger",
            confirmButtonText: "Ok, I got it!",
            cancelButtonText: "No",
            closeOnConfirm: true,
            closeOnCancel: true
        },
        function (isConfirm) {
            if(isConfirm){}
        });
})



/**
 * Panel Heading Click-to-collapse
 */

$(document).on('click', '.panel-heading span.clickable', function (e) {
    var $this = $(this);
    if (!$this.hasClass('panel-collapsed')) {
        $this.parents('.panel').find('.panel-body').slideUp();
        $this.addClass('panel-collapsed');
        $this.find('i').removeClass('glyphicon-minus').addClass('glyphicon-plus');
    } else {
        $this.parents('.panel').find('.panel-body').slideDown('slow');
        $this.removeClass('panel-collapsed');
        $this.find('i').removeClass('glyphicon-plus').addClass('glyphicon-minus');
    }
});
$(document).on('click', '.panel div.clickable', function (e) {
    var $this = $(this);
    if (!$this.hasClass('panel-collapsed')) {
        $this.parents('.panel').find('.panel-body').slideUp();
        $this.addClass('panel-collapsed');
        $this.find('i').removeClass('glyphicon-minus').addClass('glyphicon-plus');
    } else {
        $this.parents('.panel').find('.panel-body').slideDown('slow');
        $this.removeClass('panel-collapsed');
        $this.find('i').removeClass('glyphicon-plus').addClass('glyphicon-minus');
    }
});
$(document).ready(function () {
    $('.panel:not(".panel-open") .panel-heading span.clickable').click();
    $('.panel:not(".panel-open") div.clickable').click();
});

/**
 * jQuery Ellipsis
 */

// $('.ellipsis-single-line').ellipsis({
//     lines: 1,
//     responsive: true
// });

/**
 * Echo

 var echo = new Echo({
    broadcaster: 'pusher',
    key: $('meta[name="pusher-key"]').attr('content'),
    cluster: $('meta[name="pusher-cluster"]').attr('content'),
    encrypted: true
});
 **/

/**
 * STTabs
 */

(function () {
    [].slice.call(document.querySelectorAll('.sttabs')).forEach(function (el) {
        if (!$(el).hasClass('sttabs-static')) {
            new CBPFWTabs(el);
        }
    });
})();

/**
 * Top Nav Widgets
 */

$('#top-nav li.calendar a').on('click', function (event) {
    $('#top-nav li.calculator').removeClass('open');
    $(this).parent().toggleClass('open');
});

$('body').on('click', function (e) {
    if (!$('#top-nav li.calendar').is(e.target)
        && $('#top-nav li.calendar').has(e.target).length === 0
        && $('.open').has(e.target).length === 0
    ) {

        $('#top-nav li.calendar').removeClass('open');
    }
});
// $('.select2').on('select2:opening', function(e) {
//     $(this).data('select2').$dropdown.find(':input.select2-search__field').attr('placeholder', 'Search ...')
// });
$('select').on('select2:opening', function(e) {
    $(this).data('select2').$dropdown.find(':input.select2-search__field').attr('placeholder', 'Search ...')
});

$('#top-nav li.calculator a').on('click', function (event) {
    $('#top-nav li.calendar').removeClass('open');
    $(this).parent().toggleClass('open');
});

$('body').on('click', function (e) {
    if (!$('#top-nav li.calculator').is(e.target)
        && $('#top-nav li.calculator').has(e.target).length === 0
        && $('.open').has(e.target).length === 0
    ) {

        $('#top-nav li.calculator').removeClass('open');
    }
});

/**
 * Bootstrap Tooltips
 */
var init_tooltips = function () {
    $('[data-toggle="tooltip"]').each(function () {
        if (!$(this).hasClass('tooltip-init') && !$(this).hasClass('menu-tooltip')) {
            $(this).tooltip({container: 'body'});
            $(this).addClass('tooltip-init');
        }
    });
};


$(document).ready(function () {
    init_tooltips();
    function checkSessionExpiredTime(){
        setTimeout(function () {
            $.ajax({
                type: 'GET',
                url: '/dashboard',
                success: function (data) {
                    checkSessionExpiredTime()
                },
                error: function (xhr) {
                    hide_loader();
                    debug(xhr.responseText);
                    console.log("CHECK SESSION: ", xhr.status, xhr.responseText)
                    if(xhr.status === 401 && xhr.responseText.indexOf("Unauthenticated") !== -1){
                        global_notification("error","Your session has expired, you will be redirected to the login page.",5000)
                        setTimeout(function () {
                            window.location.href = window.location.protocol + "//" + window.location.hostname + "/login"
                        }, 6000)
                    }
                }
            });
        }, 60000)
    }
    checkSessionExpiredTime()
});

function editDistance(s1, s2) {
    s1 = s1.toLowerCase();
    s2 = s2.toLowerCase();

    var costs = new Array();
    for (var i = 0; i <= s1.length; i++) {
        var lastValue = i;
        for (var j = 0; j <= s2.length; j++) {
            if (i == 0)
                costs[j] = j;
            else {
                if (j > 0) {
                    var newValue = costs[j - 1];
                    if (s1.charAt(i - 1) != s2.charAt(j - 1))
                        newValue = Math.min(Math.min(newValue, lastValue),
                            costs[j]) + 1;
                    costs[j - 1] = lastValue;
                    lastValue = newValue;
                }
            }
        }
        if (i > 0)
            costs[s2.length] = lastValue;
    }
    return costs[s2.length];
}

function similarity(s1, s2) {
    var longer = s1;
    var shorter = s2;
    if (s1.length < s2.length) {
        longer = s2;
        shorter = s1;
    }
    var longerLength = longer.length;
    if (longerLength == 0) {
        return 1.0;
    }
    return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength);
}

$(document).ready(function () {
    $('#bluhorn-import-submit').on('click', function () {
        var target_adv = $('#bluhorn_match_advertiser');
        var target_camp = $('#bluhorn_target_campaign');
        var market = $('#bluhorn_market');
        var good = true;
        if (target_adv === 'new') {
            if ($('#bluhorn_advertiser').val().trim() === "" || $('#bluhorn_advertiser').val().trim().length < 4) {
                good = false;
                swal({
                        title: "Warning",
                        text: 'Missing Advertiser Name',
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonClass: "btn-danger",
                        confirmButtonText: "OK",
                        closeOnConfirm: true,
                        closeOnCancel: true
                    },
                    function (isConfirm) {
                    });
            }
        }
        if (target_camp === 'new' && good) {
            if ($('#bluhorn_campaign').val().trim() === "" || $('#bluhorn_campaign').val().trim().length < 4) {
                good = false;
                swal({
                        title: "Warning",
                        text: 'Missing Campaign Name',
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonClass: "btn-danger",
                        confirmButtonText: "OK",
                        closeOnConfirm: true,
                        closeOnCancel: true
                    },
                    function (isConfirm) {
                    });
            }
        }
        if (good) {
            $('select[name="bluhorn_outlets_vendors[]"]').each(function () {
                if ($(this).val() === "" || $(this).val() === 'undefined' || $(this).val() === null) {
                    good = false;
                    return;
                }
            });
            if (!good) {
                swal({
                        title: "Warning",
                        text: 'Missing Vendor\'s from Galeforcemedia',
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonClass: "btn-danger",
                        confirmButtonText: "OK",
                        closeOnConfirm: true,
                        closeOnCancel: true
                    },
                    function (isConfirm) {
                    });
            }
        }
        if (good) {
            // var params = $('#form-import-bluhorn').serializeArray().concat({lines: bh_lines}).concat({outlets: bh_outlets}).concat({dates: bh_weeks});
            var params = $('#form-import-bluhorn').serializeArray().reduce(function (obj, item) {
                if (item.name.indexOf('[]') !== -1) {
                    if (typeof obj[item.name] !== 'undefined')
                        obj[item.name].push(item.value);
                    else
                        obj[item.name] = [item.value];
                } else {
                    obj[item.name] = item.value;
                }
                return obj;
            }, {});
            params['dates'] = bh_weeks;
            params['outlets'] = bh_outlets;
            params['lines'] = bh_lines;
            params['media'] = (typeof bh_lines !== 'undefined' && bh_lines.length > 0 && typeof bh_lines[0]['size'] !== 'undefined') ? 'ooh' : '';
            console.log(params);
            show_overlay_loader($("#bluhornMatchModal"));
            $.ajax({
                url: $(this).attr('data-contact-url'),
                data: JSON.stringify(params),
                dataType: "json",
                contentType: "application/json",
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                type: 'POST',
                success: function (response) {
                    console.log(response);
                    global_notification('success', 'Campaign reated successfully.', 8000);
                    $('#bluhornMatchModal').modal('hide');
                    $('#importBluHornModal').modal('hide');
                    location.reload();
                },
                error: function (xhr) {
                    console.log(xhr);
                    if (xhr.status === 422) {
                        swal({
                                title: "Warning",
                                text: xhr.responseJSON.message,
                                type: "warning",
                                showCancelButton: true,
                                confirmButtonClass: "btn-danger",
                                confirmButtonText: "OK",
                                closeOnConfirm: true,
                                closeOnCancel: true
                            },
                            function (isConfirm) {

                            });
                    } else {
                        ajax_error();
                        debug(xhr.responseText);
                    }
                    //hide_loader();
                    hide_overlay_loader($("#bluhornMatchModal"))
                }
            })
        }

    })

    $('#confirm_create_bluhorn').on('click', function () {
        var urlVar12 = $(this).attr('data-contact-url');
        var file = $("#input-file-bluhorn");
        var formData = new FormData();
        formData.append('file', file[0].files[0]);
        if (file[0].files.length > 0) {
            show_overlay_loader($("#importBluHornModal"));
            $.ajax({
                url: urlVar12,
                dataType: 'json',
                data: formData,
                type: 'POST',
                processData: false,  // tell jQuery not to process the data
                contentType: false,  // tell jQuery not to set contentType
                enctype: 'multipart/form-data',
                async: true,
                cache: false,
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                success: function (file) {
                    bluhorn_advertisers = file['advertisers'];
                    bh_lines = file['lines'];
                    bh_outlets = file['outlets'];
                    bh_weeks = file['dates'];
                    modal = $('#bluhornMatchModal');
                    container = modal.find('.bluhorn-matching-container');
                    var html = '<div class="row">';
                    html += '<div class="col-md-6"><b>CSV Advertiser Name: </b> <input id="bluhorn_advertiser" name="bluhorn_advertiser" type="text" required value="' + file['advertiser'] + '" class="form-control"></div>';
                    var campaigns = [];
                    html += '<div class="col-md-6"><b>Galeforcemedia Advertiser: </b> <select class="form-control full-width" id="bluhorn_match_advertiser" name="bluhorn_match_advertiser">';
                    html += '<option value="new">Create New Advertiser</option>'
                    for (var i in file['advertisers']) {
                        var sel = '';
                        if (file['advertisers'][i]['name'] === file['advertiser']) {
                            sel = 'selected';
                            campaigns = file['advertisers'][i]['campaigns'];
                        }
                        html += '<option ' + sel + ' value="' + file['advertisers'][i]['id'] + '">' + file['advertisers'][i]['name'] + '</option>';
                    }
                    html += '</select></div>';
                    html += '<div class="col-md-6"><b>CSV Campaign Name: </b> <input id="bluhorn_campaign" name="bluhorn_campaign" type="text" required value="' + file['campaign'] + '" class="form-control"></div>';
                    html += '<div class="col-md-6"><b>Galeforcemedia Campaign: </b> <select class="form-control full-width" id="bluhorn_match_campaign" name="bluhorn_match_campaign">';
                    html += '<option value="new">Create New Campaign</option>'
                    for (var i in campaigns) {
                        var sel = '';
                        if (campaigns[i]['name'] === file['campaign']) {
                            sel = 'selected';
                        }
                        html += '<option ' + sel + ' value="' + campaigns[i]['id'] + '">' + campaigns[i]['name'] + '</option>';
                    }
                    html += '</select></div>';
                    html += '<div class="col-md-6"><b>Worksheet Name: </b> <input id="bluhorn_ws_name" name="bluhorn_ws_name" type="text" required value="' + file['worksheet'] + '" class="form-control"></div>';
                    html += '<div class="col-md-6"><b>Market: </b> <select class="form-control full-width  bluhorn_market_class" id="bluhorn_market" name="bluhorn_market">';
                    for (var j in file['markets']) {
                        html += '<option ' + ((file['market'] !== null && file['market'] === file['markets'][j]['id']) ? 'selected' : '') + ' value="' + file['markets'][j]['id'] + '">' + file['markets'][j]['type'].toUpperCase() + ' - ' + file['markets'][j]['name'] + '</option>';
                    }
                    html += '</select></div>';
                    html += '</div><br/>';
                    var x = 1;
                    html += '<p>Please match at least one Vendor from the file to one Vendor in Media Force.</p>';
                    html += '<div id="bhvendors">';
                    $.each(file['outlets'], function (index, value) {

                        html += '<div class="row"><div class="col-sm-4"><b>';
                        if (value['band'] !== '')
                            html += value['name'] + '-' + value['band'];
                        else {
                            html += (typeof value['network'] !== 'undefined' && value['network'] !== "") ? "Syscode: " + value['name'] : value['name'];
                        }
                        html += '</b><input type="hidden" id="bluhorn_outlets" name="bluhorn_outlets[]" value="' + value['id'] + '">';
                        html += '</div><div class="col-sm-8 div_bluhorn_name">';

                        html += '<select class="form-control full-width  bluhorn_outlets_vendors_class" syscode="' + value['syscode'] + '" matchto="' + (value['band'] !== '' ? (value['name'] + '-' + value['band']) : value['name']) + '" id="bluhorn_outlets_vendors_' + index + '" name="bluhorn_outlets_vendors[]">';

                        if (typeof value['syscode'] !== 'undefined' && value['syscode'] !== -1) {
                            for (var ii in file['cbl_stations']) {
                                html += '<option value="' + file['cbl_stations'][ii]['slug'] + '">' + file['cbl_stations'][ii]['name'] + '</option>';
                            }
                        } else {
                            var vendors_slg = ["", "", ""];
                            var vendors_str = ["", "", ""];
                            var vendors_prc = [0, 0, 0];
                            $.each(file['vendors'], function (index, value1) {
                                var prc = similarity(value['name'], value1['name']);
                                var change = false;
                                $.each(vendors_str, function (index, value2) {
                                    if (!change && prc > vendors_prc[index]) {
                                        change = true;
                                        vendors_str[index] = value1['name'];
                                        vendors_slg[index] = value1['slug'];
                                        vendors_prc[index] = prc;
                                    }
                                });
                                if (vendors_prc[0] > vendors_prc[1]) {
                                    var aux1 = vendors_prc[0];
                                    var aux2 = vendors_str[0];
                                    var aux3 = vendors_slg[0];
                                    vendors_prc[0] = vendors_prc[1];
                                    vendors_str[0] = vendors_str[1];
                                    vendors_slg[0] = vendors_slg[1];
                                    vendors_prc[1] = aux1;
                                    vendors_str[1] = aux2;
                                    vendors_slg[1] = aux3;
                                }
                                if (vendors_prc[0] > vendors_prc[2]) {
                                    var aux1 = vendors_prc[0];
                                    var aux2 = vendors_str[0];
                                    var aux3 = vendors_slg[0];
                                    vendors_prc[0] = vendors_prc[2];
                                    vendors_str[0] = vendors_str[2];
                                    vendors_slg[0] = vendors_slg[2];
                                    vendors_prc[2] = aux1;
                                    vendors_str[2] = aux2;
                                    vendors_slg[2] = aux3;
                                }
                                if (vendors_prc[1] > vendors_prc[2]) {
                                    var aux1 = vendors_prc[1];
                                    var aux2 = vendors_str[1];
                                    var aux3 = vendors_slg[1];
                                    vendors_prc[1] = vendors_prc[2];
                                    vendors_str[1] = vendors_str[2];
                                    vendors_slg[1] = vendors_slg[2];
                                    vendors_prc[2] = aux1;
                                    vendors_str[2] = aux2;
                                    vendors_slg[2] = aux3;
                                }
                            });
                            if (typeof value['syscode'] === 'undefined' || value['syscode'] === -1) {
                                if (vendors_prc[0] < vendors_prc[1]) {
                                    var aux1 = vendors_prc[0];
                                    var aux2 = vendors_str[0];
                                    var aux3 = vendors_slg[0];
                                    vendors_prc[0] = vendors_prc[1];
                                    vendors_str[0] = vendors_str[1];
                                    vendors_slg[0] = vendors_slg[1];
                                    vendors_prc[1] = aux1;
                                    vendors_str[1] = aux2;
                                    vendors_slg[1] = aux3;
                                }
                                if (vendors_prc[0] < vendors_prc[2]) {
                                    var aux1 = vendors_prc[0];
                                    var aux2 = vendors_str[0];
                                    var aux3 = vendors_slg[0];
                                    vendors_prc[0] = vendors_prc[2];
                                    vendors_str[0] = vendors_str[2];
                                    vendors_slg[0] = vendors_slg[2];
                                    vendors_prc[2] = aux1;
                                    vendors_str[2] = aux2;
                                    vendors_slg[2] = aux3;
                                }
                                if (vendors_prc[1] < vendors_prc[2]) {
                                    var aux1 = vendors_prc[1];
                                    var aux2 = vendors_str[1];
                                    var aux3 = vendors_slg[1];
                                    vendors_prc[1] = vendors_prc[2];
                                    vendors_str[1] = vendors_str[2];
                                    vendors_slg[1] = vendors_slg[2];
                                    vendors_prc[2] = aux1;
                                    vendors_str[2] = aux2;
                                    vendors_slg[2] = aux3;
                                }
                                //if (vendors_str[0] == '') {
                                var similarities = []
                                $.each(file['vendors'], function (index, value2) {
                                    similarities.push([index, similarity(value['name'], value2['name'])]);
                                });
                                var likely_index = similarities.length > 0 ? similarities.sort((a, b) => a[1] - b[1])[similarities.length - 1][0] : -1;
                                $.each(file['vendors'], function (index, value) {
                                    var selected = (likely_index == index) ? "selected" : ""
                                    html += '<option value="' + value['slug'] + '" ' + selected + '>' + value['name'] + '</option>';
                                });
                            }
                        }
                        html += '</select> </div></div>';
                        x++;
                    });
                    html += '</div>';

                    html += '<input type="hidden" name="parser_path" id="parser_path" value="' + file['parser'] + '">';

                    html += '<br/><br/><div class="row">';
                    html += '<div class="col-md-6"><b>' + file['media'].toUpperCase() + ' Demo #1: </b></div><div class="col-md-6">';
                    html += '<select id="bluhorn_demo" name="bluhorn_demo" class="form-control full-width">';
                    if (file['media'] === 'tv') {
                        if (typeof file['demos']['p'] !== 'undefined') {
                            for (var h in file['demos']) {
                                if (h === 'p') {
                                    for (var l in file['demos'][h])
                                        html += '<option value="' + file['demos'][h][l] + '">People ' + l + '</option>';
                                } else if (h === 'm') {
                                    for (var l in file['demos'][h])
                                        html += '<option value="' + file['demos'][h][l] + '">Male ' + l + '</option>';
                                } else {
                                    for (var l in file['demos'][h])
                                        html += '<option value="' + file['demos'][h][l] + '">Female ' + l + '</option>';
                                }
                            }
                        } else {
                            for (var h in file['demos']) {
                                if (file['demos'][h]['blocks']['p'].length > 0)
                                    html += '<option value="' + file['demos'][h]['blocks']['p'].join() + '">People ' + file['demos'][h]['start'] + ((file['demos'][h]['end'] !== null) ? '-' + file['demos'][h]['end'] : '+') + '</option>';
                                if (file['demos'][h]['blocks']['m'].length > 0)
                                    html += '<option value="' + file['demos'][h]['blocks']['m'].join() + '">Male ' + file['demos'][h]['start'] + ((file['demos'][h]['end'] !== null) ? '-' + file['demos'][h]['end'] : '+') + '</option>';
                                if (file['demos'][h]['blocks']['f'].length > 0)
                                    html += '<option value="' + file['demos'][h]['blocks']['f'].join() + '">Female ' + file['demos'][h]['start'] + ((file['demos'][h]['end'] !== null) ? '-' + file['demos'][h]['end'] : '+') + '</option>';
                            }
                        }
                    } else {
                        for (var h in file['demos']) {
                            if (file['demos'][h]['blocks']['p'].length > 0)
                                html += '<option value="' + file['demos'][h]['blocks']['p'].join() + '">People ' + file['demos'][h]['start'] + ((file['demos'][h]['end'] !== null) ? '-' + file['demos'][h]['end'] : '+') + '</option>';
                            if (file['demos'][h]['blocks']['m'].length > 0)
                                html += '<option value="' + file['demos'][h]['blocks']['m'].join() + '">Male ' + file['demos'][h]['start'] + ((file['demos'][h]['end'] !== null) ? '-' + file['demos'][h]['end'] : '+') + '</option>';
                            if (file['demos'][h]['blocks']['f'].length > 0)
                                html += '<option value="' + file['demos'][h]['blocks']['f'].join() + '">Female ' + file['demos'][h]['start'] + ((file['demos'][h]['end'] !== null) ? '-' + file['demos'][h]['end'] : '+') + '</option>';
                        }
                    }
                    html += '</select>';
                    html += '</div>';

                    if (typeof file['outlets'][0]['share_'] !== 'undefined') {
                        html += '<div class="col-md-6"><b>' + file['media'].toUpperCase() + ' Demo #2: </b></div><div class="col-md-6">';
                        html += '<select id="bluhorn_demo2" name="bluhorn_demo2" class="form-control full-width">';
                        if (file['media'] === 'tv') {
                            if (typeof file['demos']['p'] !== 'undefined') {
                                for (var h in file['demos']) {
                                    if (h === 'p') {
                                        for (var l in file['demos'][h])
                                            html += '<option value="' + file['demos'][h][l] + '">Adults ' + l + '</option>';
                                    } else if (h === 'm') {
                                        for (var l in file['demos'][h])
                                            html += '<option value="' + file['demos'][h][l] + '">Male ' + l + '</option>';
                                    } else {
                                        for (var l in file['demos'][h])
                                            html += '<option value="' + file['demos'][h][l] + '">Female ' + l + '</option>';
                                    }
                                }
                            } else {
                                for (var h in file['demos']) {
                                    if (file['demos'][h]['blocks']['p'].length > 0)
                                        html += '<option value="' + file['demos'][h]['blocks']['p'].join() + '">People ' + file['demos'][h]['start'] + ((file['demos'][h]['end'] !== null) ? '-' + file['demos'][h]['end'] : '+') + '</option>';
                                    if (file['demos'][h]['blocks']['m'].length > 0)
                                        html += '<option value="' + file['demos'][h]['blocks']['m'].join() + '">Male ' + file['demos'][h]['start'] + ((file['demos'][h]['end'] !== null) ? '-' + file['demos'][h]['end'] : '+') + '</option>';
                                    if (file['demos'][h]['blocks']['f'].length > 0)
                                        html += '<option value="' + file['demos'][h]['blocks']['f'].join() + '">Female ' + file['demos'][h]['start'] + ((file['demos'][h]['end'] !== null) ? '-' + file['demos'][h]['end'] : '+') + '</option>';
                                }
                            }
                        } else {
                            for (var h in file['demos']) {
                                if (file['demos'][h]['blocks']['p'].length > 0)
                                    html += '<option value="' + file['demos'][h]['blocks']['p'].join() + '">People ' + file['demos'][h]['start'] + ((file['demos'][h]['end'] !== null) ? '-' + file['demos'][h]['end'] : '+') + '</option>';
                                if (file['demos'][h]['blocks']['m'].length > 0)
                                    html += '<option value="' + file['demos'][h]['blocks']['m'].join() + '">Male ' + file['demos'][h]['start'] + ((file['demos'][h]['end'] !== null) ? '-' + file['demos'][h]['end'] : '+') + '</option>';
                                if (file['demos'][h]['blocks']['f'].length > 0)
                                    html += '<option value="' + file['demos'][h]['blocks']['f'].join() + '">Female ' + file['demos'][h]['start'] + ((file['demos'][h]['end'] !== null) ? '-' + file['demos'][h]['end'] : '+') + '</option>';
                            }
                        }
                        html += '</select>';
                        html += '</div>';
                    }

                    html += '</div><br/>';

                    container.html(html);
                    $('#bhvendors').find('select').each(function () {
                        $(this).select2({
                            placeholder: "...",
                            minimumResultsForSearch: 3
                        }).bind('select2:select', function () {

                        });
                    })
                    $('#bluhornMatchModal').modal('hide');
                    container.find('.bluhorn_vendors_class').select2({
                        placeholder: "Select one...",
                        minimumResultsForSearch: 3,
                        dropdownParent: $('.worksheet-market')
                    });
                    $('#bluhorn_demo').select2({
                        placeholder: "...",
                        minimumResultsForSearch: 3
                    }).bind('select2:select', function () {

                    });
                    $('#bluhorn_match_advertiser').select2({
                        placeholder: "...",
                        minimumResultsForSearch: 3
                    }).bind('select2:select', function () {
                        show_overlay_loader($("#bluhornMatchModal"));
                        if ($(this).val() === 'new') {
                            $('#bluhorn_match_campaign').html('<option value="new">Create New Campaign</option>');
                        } else {
                            var campaigns = collect(bluhorn_advertisers).where('id', '=', parseInt($(this).val())).first().campaigns;
                            var html = '<option value="new">Create New Campaign</option>';
                            for (var i in campaigns) {
                                var sel = '';
                                if (campaigns[i].name === $('#bluhorn_campaign').val()) {
                                    sel = 'selected';
                                }
                                html += '<option ' + sel + ' value="' + campaigns[i].id + '">' + campaigns[i].name + '</option>';
                            }
                            $('#bluhorn_match_campaign').html(html);
                        }
                        $('#bluhorn_match_campaign').select2({
                            placeholder: "...",
                            minimumResultsForSearch: 10
                        }).bind('select2:select', function () {

                        });
                        hide_overlay_loader($("#bluhornMatchModal"));
                    });
                    $('#bluhorn_match_campaign').select2({
                        placeholder: "...",
                        minimumResultsForSearch: 3
                    }).bind('select2:select', function () {

                    });
                    $('#bluhorn_market').select2({
                        placeholder: "...",
                        minimumResultsForSearch: 3
                    }).bind('select2:select', function () {
                        show_overlay_loader($("#bluhornMatchModal"));
                        var market_id = $(this).val();
                        $.ajax({
                            url: '/market/' + market_id + '/vendors/' + file['media'],
                            dataType: 'json',
                            type: 'GET',
                            success: function (data) {
                                $('.bluhorn_outlets_vendors_class').each(function () {
                                    var matchto = $(this).attr('matchto');
                                    var syscode = $(this).attr('syscode');
                                    console.log('syscode: ' + syscode);

                                    var html = '';
                                    if (syscode !== 'undefined' && syscode !== '-1' && syscode !== '') {
                                        for (var ii in data['cbl_stations']) {
                                            html += '<option value="' + data['cbl_stations'][ii]['slug'] + '">' + data['cbl_stations'][ii]['name'] + '</option>';
                                        }
                                    } else {
                                        if(file['media'] == 'tv'){
                                            for(var ind in data['cbl_stations'])
                                                data['vendors'].push(data['cbl_stations'][ind]);
                                        }
                                        var vendors_slg = ["", "", ""];
                                        var vendors_str = ["", "", ""];
                                        var vendors_prc = [0, 0, 0];
                                        $.each(data['vendors'], function (index, value1) {
                                            var prc = similarity(matchto, value1['name']);
                                            var change = false;
                                            $.each(vendors_str, function (index, value2) {
                                                if (!change && prc > vendors_prc[index]) {
                                                    change = true;
                                                    vendors_str[index] = value1['name'];
                                                    vendors_slg[index] = value1['slug'];
                                                    vendors_prc[index] = prc;
                                                }
                                            });
                                            if (vendors_prc[0] > vendors_prc[1]) {
                                                var aux1 = vendors_prc[0];
                                                var aux2 = vendors_str[0];
                                                var aux3 = vendors_slg[0];
                                                vendors_prc[0] = vendors_prc[1];
                                                vendors_str[0] = vendors_str[1];
                                                vendors_slg[0] = vendors_slg[1];
                                                vendors_prc[1] = aux1;
                                                vendors_str[1] = aux2;
                                                vendors_slg[1] = aux3;
                                            }
                                            if (vendors_prc[0] > vendors_prc[2]) {
                                                var aux1 = vendors_prc[0];
                                                var aux2 = vendors_str[0];
                                                var aux3 = vendors_slg[0];
                                                vendors_prc[0] = vendors_prc[2];
                                                vendors_str[0] = vendors_str[2];
                                                vendors_slg[0] = vendors_slg[2];
                                                vendors_prc[2] = aux1;
                                                vendors_str[2] = aux2;
                                                vendors_slg[2] = aux3;
                                            }
                                            if (vendors_prc[1] > vendors_prc[2]) {
                                                var aux1 = vendors_prc[1];
                                                var aux2 = vendors_str[1];
                                                var aux3 = vendors_slg[1];
                                                vendors_prc[1] = vendors_prc[2];
                                                vendors_str[1] = vendors_str[2];
                                                vendors_slg[1] = vendors_slg[2];
                                                vendors_prc[2] = aux1;
                                                vendors_str[2] = aux2;
                                                vendors_slg[2] = aux3;
                                            }
                                        });
                                        if (syscode === 'undefined' || syscode === '-1' || syscode === '') {
                                            if (vendors_prc[0] < vendors_prc[1]) {
                                                var aux1 = vendors_prc[0];
                                                var aux2 = vendors_str[0];
                                                var aux3 = vendors_slg[0];
                                                vendors_prc[0] = vendors_prc[1];
                                                vendors_str[0] = vendors_str[1];
                                                vendors_slg[0] = vendors_slg[1];
                                                vendors_prc[1] = aux1;
                                                vendors_str[1] = aux2;
                                                vendors_slg[1] = aux3;
                                            }
                                            if (vendors_prc[0] < vendors_prc[2]) {
                                                var aux1 = vendors_prc[0];
                                                var aux2 = vendors_str[0];
                                                var aux3 = vendors_slg[0];
                                                vendors_prc[0] = vendors_prc[2];
                                                vendors_str[0] = vendors_str[2];
                                                vendors_slg[0] = vendors_slg[2];
                                                vendors_prc[2] = aux1;
                                                vendors_str[2] = aux2;
                                                vendors_slg[2] = aux3;
                                            }
                                            if (vendors_prc[1] < vendors_prc[2]) {
                                                var aux1 = vendors_prc[1];
                                                var aux2 = vendors_str[1];
                                                var aux3 = vendors_slg[1];
                                                vendors_prc[1] = vendors_prc[2];
                                                vendors_str[1] = vendors_str[2];
                                                vendors_slg[1] = vendors_slg[2];
                                                vendors_prc[2] = aux1;
                                                vendors_str[2] = aux2;
                                                vendors_slg[2] = aux3;
                                            }

                                            var similarities = []
                                            $.each(data['vendors'], function (index, value2) {
                                                similarities.push([index, similarity(matchto, value2['name'])]);
                                            });
                                            if (similarities.length > 0)
                                                var likely_index = similarities.length > 0 ? similarities.sort((a, b) => a[1] - b[1])[similarities.length - 1][0] : -1
                                            $.each(data['vendors'], function (index, value) {
                                                var selected = (likely_index == index) ? "selected" : ""
                                                html += '<option value="' + value['slug'] + '" ' + selected + '>' + value['name'] + '</option>';
                                            });
                                        }
                                    }

                                    $(this).html(html);
                                    $(this).select2({
                                        placeholder: "...",
                                        minimumResultsForSearch: 3
                                    }).bind('select2:select', function () {

                                    });
                                })
                                hide_overlay_loader($("#bluhornMatchModal"));
                            },
                            error: function (xhr) {
                                if (xhr.status === 422) {
                                    swal({
                                            title: "Warning",
                                            text: xhr.responseJSON.message,
                                            type: "warning",
                                            showCancelButton: true,
                                            confirmButtonClass: "btn-danger",
                                            confirmButtonText: "OK",
                                            closeOnConfirm: true,
                                            closeOnCancel: true
                                        },
                                        function (isConfirm) {

                                        });
                                } else {
                                    ajax_error();
                                    debug(xhr.responseText);
                                }
                                hide_overlay_loader($("#bluhornMatchModal"));
                            }
                        });
                    });
                    modal.modal('show');
                    hide_overlay_loader($("#importBluHornModal"))
                },
                error: function (xhr) {
                    console.log(xhr);
                    if (xhr.status === 422) {
                        swal({
                                title: "Warning",
                                text: xhr.responseJSON.message,
                                type: "warning",
                                showCancelButton: true,
                                confirmButtonClass: "btn-danger",
                                confirmButtonText: "OK",
                                closeOnConfirm: true,
                                closeOnCancel: true
                            },
                            function (isConfirm) {

                            });
                    } else {
                        ajax_error();
                        debug(xhr.responseText);
                    }
                    //hide_loader();
                    hide_overlay_loader($("#importBluHornModal"))
                }
            })
        }
    });

    $('#new_import_bluhorn').on('click', function () {
        console.log('in');

        var modal = $('#importBluHornModal');
        var container = modal.find('#bluhorn_upload_container');

        var html = '<label for="input-file-now">CSV File</label><input type="file" id="input-file-bluhorn" class="dropify" data-allowed-file-extensions="csv">';
        container.html(html);

        var drop = $('.dropify').dropify();
        $('.dropify-fr').dropify({
            messages: {
                default: 'Drag and drop a file here or click',
                replace: 'Drag and drop or click to replace',
                remove: 'Remove',
                error: 'Ooops, something wrong happened.'
            }
        });

        // Used events
        var drEvent = $('#input-file-events').dropify();
        drEvent.on('dropify.beforeClear', function (event, element) {
            return confirm("Do you really want to delete \"" + element.file.name + "\" ?");
        });
        drEvent.on('dropify.afterClear', function (event, element) {
            alert('File deleted');
        });
        drEvent.on('dropify.errors', function (event, element) {
            //console.log('Has Errors');
        });
        var drDestroy = $('#input-file-to-destroy').dropify();
        drDestroy = drDestroy.data('dropify')
        $('#toggleDropify').on('click', function (e) {
            e.preventDefault();
            if (drDestroy.isDropified()) {
                drDestroy.destroy();
            } else {
                drDestroy.init();
            }
        })
        modal.modal('show');
    })
});
/**
 * Global Ajax Error Handling
 */

var ajax_error = function () {
   // global_notification('error', 'There was an issue processing that request. Please contact support if the issue persists.');
};

/**
 * Moment
 **/

moment().locale('en');

/**
 * Global notifications
 **/

var global_notification_icons = {
    alert: '<i class="fa fa-bullhorn fa-lg fa-pull-left fa-fw"></i>',
    success: '<i class="fa fa-check fa-lg fa-pull-left fa-fw"></i>',
    error: '<i class="fa fa-exclamation-triangle fa-lg fa-pull-left fa-fw"></i>',
    warning: '<i class="fa fa-bell fa-lg fa-pull-left fa-fw"></i>',
    info: '<i class="fa fa-info-circle fa-lg fa-pull-left fa-fw"></i>'
};

var ws_global_notification_queue = {};

function global_notification(type, text, timeout, id, buttons) {
    if (typeof timeout === 'undefined') {
        timeout = 4000;
    }
    if (typeof buttons === 'undefined') {
        buttons = [];
    }

    // If we're repeating the same notification id, reset timeout instead of duplicating.
    if (typeof id === 'undefined') {
        new Noty({
            type: type,
            layout: 'topRight',
            theme: 'bootstrap-v3',
            text: global_notification_icons[type] + text,
            timeout: timeout,
            progressBar: true,
            closeWith: ['click', 'button'],
            animation: {
                open: 'animated fadeInRight',
                close: 'animated fadeOutRight'
            },
            visibilityControl: false,
            buttons: buttons
        }).show();
    } else {
        if (!ws_global_notification_queue.hasOwnProperty(id)) {
            new_noty = new Noty({
                type: type,
                layout: 'topRight',
                theme: 'bootstrap-v3',
                text: global_notification_icons[type] + text,
                timeout: timeout,
                progressBar: true,
                closeWith: ['click', 'button'],
                animation: {
                    open: 'animated fadeInRight',
                    close: 'animated fadeOutRight'
                },
                visibilityControl: false,
                id: id,
                buttons: buttons
            }).on('onClose', function () {
                delete ws_global_notification_queue[this.id];
            });
            ws_global_notification_queue[id] = new_noty;
            new_noty.show();
        } else {
            ws_global_notification_queue[id].setTimeout(timeout);
        }
    }

}


/*
 * Client Side Error Logging
 */

window.onerror = function (e) {
    try {
        $.ajax({
            type: 'GET',
            url: '/log_error',
            data: 'error=' + e
        });
    } catch (err) {
    }
    return false;
};


/**
 * Searchable multiSelect definition
 */
var searchableMultiSelectDef = {

    selectableHeader: "<i class='fa fa-search search-icon-input'></i><input type='text' class='search-input full-width form-control' autocomplete='off' placeholder='Search...'>",
    selectionHeader: "<div style='height: 38px;'><label>Selected</label></div>",
    afterInit: function(ms){
        var that = this,
            $selectableSearch = that.$selectableUl.prev(),
            selectableSearchString = '#'+that.$container.attr('id')+' .ms-elem-selectable:not(.ms-selected)';

        that.qs1 = $selectableSearch.quicksearch(selectableSearchString)
            .on('keydown', function(e){
                if (e.which === 40){
                    that.$selectableUl.focus();
                    return false;
                }
            });
    },
    afterSelect: function(){
        this.qs1.cache();
    },
    afterDeselect: function(){
        this.qs1.cache();
    }
};


var marketMultiSelect = function(id){
    $(id).multiSelect({
        selectableHeader: "<i class='fa fa-search search-icon-input'></i><input type='text' class='search-input full-width form-control' autocomplete='off' placeholder='Search...'>",
        selectionHeader: "<div style='height: 38px;'><label>Selected</label></div>",
        afterInit: function(ms){
            var that = this,
                $selectableSearch = that.$selectableUl.prev(),
                selectableSearchString = '#'+that.$container.attr('id')+' .ms-elem-selectable:not(.ms-selected)';

            that.qs1 = $selectableSearch.quicksearch(selectableSearchString)
                .on('keydown', function(e){
                    if (e.which === 40){
                        that.$selectableUl.focus();
                        return false;
                    }
                });
        },
        afterSelect: function(values){
            this.qs1.cache();
        },
        afterDeselect: function(values){
            this.qs1.cache();
        }
    });
};
